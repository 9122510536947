
import { mapWritableState } from 'pinia';
import { defineComponent } from 'vue';
import { signIn } from '@/services/steamcord/auth';
import { validateSignInData } from '@/services/validation/auth';
import { useAuthStore } from '@/store/authStore';
import setAuthorizationHeader from '@/util/axios';

export default defineComponent({
  computed: {
    ...mapWritableState(useAuthStore, ['emailVerified', 'token']),
  },
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      formError: '',
      signInErrors: {
        emailError: '',
        passwordError: '',
        hasErrors: false,
      },
    };
  },
  methods: {
    async onSubmit(): Promise<void> {
      if (this.loading) {
        return;
      }

      this.signInErrors = validateSignInData(this.email, this.password);
      if (this.signInErrors.hasErrors) {
        return;
      }

      this.loading = true;

      const response = await signIn(this.email, this.password);
      if (!response) {
        this.formError = 'Invalid email and password combination.';
        this.loading = false;
        return;
      }

      this.emailVerified = response.emailVerified;
      this.token = response.token;

      localStorage.setItem('token', response.token);
      setAuthorizationHeader(response.token);

      if (response.emailVerified) {
        const redirectRoute = this.$route.query.redirect?.toString() ?? '/dashboard';
        this.$router.push(redirectRoute);
        return;
      }

      // TODO: pass redirect parameter?
      this.$router.push({ name: 'Sign In Verify Email', params: { email: this.email } });
    },
  },
});
